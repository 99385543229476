import Dh from "@/helpers/date.helper";

import DeleteModal from "../Modals/DeleteModal.vue";

import AutomationService from "@/services/automation.service";
const service = new AutomationService();

export default {
  name: "AutomationsList",

  components: {
    DeleteModal
  },

  data() {
    return {
      showDeleteModal: false,
      automation: null,

      automations: [],
      currentPage: 1,
      perPage: 25,
      totalRows: 0
    }
  },

  computed: {},

  async mounted() {
    await this.list();
  },

  methods: {
    async list(page) {
      this.currentPage = page || 1;
      const skip = this.perPage * (this.currentPage - 1);

      this.automations = [];

      const res = await service.list(this.perPage, skip);
      if (res && !res.error) {
        this.automations = res.data.automations;
        this.totalRows = res.data.count;
      }
    },

    async addAutomation() {
      await this.$router.push({name: 'AutomationsCreate'});
    },

    async editAutomation(automation) {
      await this.$router.push({name: 'AutomationsDetails', params: {id: automation._id}});
    },

    toggleDeleteModal(automation) {
      this.automation = automation || null;
      this.showDeleteModal = !this.showDeleteModal;
    },

    onDeleteAutomation({automationId}) {
      this.showDeleteModal = false;

      const index = this.automations.findIndex(el => el._id === automationId);
      if (index > -1) {
        this.automations.splice(index, 1);
        this.automation = null;
      }
    },

    async onToggleEnabled(automation) {
      const res = await service.edit(automation);
      if (res && res.error) {
        this.$notify({type: 'error', message: this.$t('edit_error')});
      }
    },

    setPerPage(num) {
      this.perPage = +(num);
      this.$hideModals();
      this.list();
    },

    next() {
      this.list(this.currentPage + 1);
    },

    prev() {
      this.list(this.currentPage - 1);
    },
  },

  filters: {
    createdAt(date) {
      return Dh.parseDate(date);
    },
  },
};