<template>
  <Modal type="delete" :close="onClose" width="500px">
    <div slot="mBody" class="m-body">
      <div v-html="$t('delete_confirmation', {automationName})"/>
    </div>
    <div slot="mFooter" class="m-footer btn-actions">
      <Button type="cancel" :text="$t('cancel')" @click="onClose"/>
      <Button type="primary" :text="$t('yes_delete')" @click="remove" :isLoading="btnLoading"/>
    </div>
  </Modal>
</template>

<script>
import AutomationService from "@/services/automation.service";
const service = new AutomationService();

export default {
  name: "DeleteAutomation",

  props: {
    automation: Object,
    onClose: Function,
    onDelete: Function,
  },

  data() {
    return {
      btnLoading: false
    }
  },

  computed: {
    automationName() {
      return this.automation.Name;
    }
  },

  methods: {
    async remove() {
      this.btnLoading = true;

      const res = await service.delete(this.automation._id);

      this.btnLoading = true;

      if (res && !res.error) {
        await this.onRemove();
        this.$notify({type: 'success', message: this.$t('delete_success')});

      } else {
        this.$notify({type: 'error', message: this.$t('delete_error')});
      }
    },

    async onRemove() {
      if (
          this.$route.name === 'AutomationsList'
      ) {
        this.onDelete({automationId: this.automation._id});
      }

      if (
          this.$route.name === 'AutomationsDetails'
      ) {
        this.onClose();
        await this.$router.push({name: 'AutomationsList'});
      }
    },
  },
}
</script>

<style scoped lang="scss">

.m-body {

  div {
    font-weight: $font-regular !important;
  }
}
</style>

<i18n>
{
  "en": {
    "cancel": "Cancel",
    "yes_delete": "Yes, Automation",
    "delete_confirmation": "Are you sure you want to delete <span style='font-weight: bold'>{automationName}</span>?",
    "delete_error": "Error deleting automation",
    "delete_success": "Automation successfully deleted"
  },
  "es": {
    "cancel": "Cancel",
    "yes_delete": "Yes, Automation",
    "delete_confirmation": "Are you sure you want to delete <span style='font-weight: bold'>{automationName}</span>?",
    "delete_error": "Error deleting automation",
    "delete_success": "Automation successfully deleted"
  }
}
</i18n>